var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "contest-entry-complete" }, [
    _c("h2", [_vm._v("応募が完了しました")]),
    _c("p", [_vm._v("ご応募いただきありがとうございました。")]),
    _c("p", [
      _vm._v(
        "選考結果の発表は下記の日程にて予定しておりますので、コンテストサイトにてご確認いただけますと幸いです。"
      ),
    ]),
    _c("br"),
    _c(
      "table",
      { attrs: { border: "1" } },
      _vm._l(_vm.contest.schedule, function (schedule) {
        return _c("tr", { key: schedule.name }, [
          _c("th", [_vm._v(_vm._s(schedule.name))]),
          _c("td", [_vm._v(_vm._s(schedule.value))]),
        ])
      }),
      0
    ),
    _c("br"),
    _c("p", [
      _vm._v(
        "なお、応募内容はメールアドレスまで送付しておりますので、お手隙の際にご確認をお願いいたします。"
      ),
    ]),
    _c("br"),
    _c("p", [_vm._v("この度はご参加くださりありがとうございました。")]),
    _c("p", [_vm._v("今後とも何卒よろしくお願いいたします。")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }