var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "tag-item" },
    [
      _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
      !_vm.readonly
        ? _c("CloseCircleIcon", {
            staticClass: "icon",
            attrs: { fillColor: "#F3F3F2" },
            on: { click: _vm.onClickDelete },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }