var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "border border-radius border-box padding" }, [
    _c(
      "div",
      {
        staticClass:
          "font-size-large font-bold border-bottom padding-bottom padding-left",
      },
      [_vm._v("執筆日記")]
    ),
    _c("div", { staticClass: "grid" }, [
      _c("div", { staticClass: "border-right" }, [
        _c("div", { staticClass: "margin-bottom padding-left" }, [
          _vm._v("今日の文字数"),
        ]),
        _c("div", { staticClass: "font-bold font-size-large padding-left" }, [
          _vm._v(_vm._s(_vm.todayWritingCount) + " 字"),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "margin-bottom padding-left" }, [
          _vm._v("過去7日間の文字数"),
        ]),
        _c("div", { staticClass: "font-bold font-size-large padding-left" }, [
          _vm._v(_vm._s(_vm.last7DaysWritingCount) + " 字"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }