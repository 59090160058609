import { render, staticRenderFns } from "./SideMenu.vue?vue&type=template&id=2a374018&scoped=true"
import script from "./SideMenu.vue?vue&type=script&lang=ts"
export * from "./SideMenu.vue?vue&type=script&lang=ts"
import style0 from "./SideMenu.vue?vue&type=style&index=0&id=2a374018&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a374018",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/yamashitatakanori/project/web-studio-yhawk/indent/nola-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a374018')) {
      api.createRecord('2a374018', component.options)
    } else {
      api.reload('2a374018', component.options)
    }
    module.hot.accept("./SideMenu.vue?vue&type=template&id=2a374018&scoped=true", function () {
      api.rerender('2a374018', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/SideMenu.vue"
export default component.exports