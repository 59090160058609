var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "root" }, [
    _c("div", { staticClass: "center" }, [
      _c("h1", [_vm._v("メールアドレスの変更")]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "left" },
        [
          _c("text-field", {
            staticClass: "text-field",
            attrs: {
              type: "email",
              label: "現在のメールアドレス",
              placeholder: "現在のメールアドレスを入力",
              name: "email-old",
              disabled: "",
            },
            model: {
              value: _vm.oldMailAddress,
              callback: function ($$v) {
                _vm.oldMailAddress = $$v
              },
              expression: "oldMailAddress",
            },
          }),
          _c("text-field", {
            staticClass: "text-field",
            attrs: {
              type: "email",
              label: "新しいメールアドレス",
              placeholder: "新しいメールアドレスを入力",
              name: "email-new",
            },
            model: {
              value: _vm.newMailAddress,
              callback: function ($$v) {
                _vm.newMailAddress = $$v
              },
              expression: "newMailAddress",
            },
          }),
        ],
        1
      ),
      _vm.message
        ? _c("div", { staticClass: "info" }, [_vm._v(_vm._s(_vm.message))])
        : _vm._e(),
      _c(
        "button",
        {
          staticClass: "button",
          attrs: {
            disabled:
              _vm.processing || !_vm.oldMailAddress || !_vm.newMailAddress,
          },
          on: { click: _vm.submit },
        },
        [_vm._v(" 変更する ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(" 下記のフォームよりメールアドレスの変更を行なってください。"),
      _c("br"),
      _vm._v(" 変更した後、本人確認のためのメールが届きます。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }