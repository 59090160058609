var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "root" }, [
    _c(
      "div",
      { staticClass: "center" },
      [
        _c("h1", [_vm._v("パスワードの変更")]),
        _vm._m(0),
        _c("text-field", {
          attrs: {
            type: "password",
            label: "現在のパスワード",
            name: "password-old",
          },
          model: {
            value: _vm.passwordOld,
            callback: function ($$v) {
              _vm.passwordOld = $$v
            },
            expression: "passwordOld",
          },
        }),
        _c("text-field", {
          attrs: {
            type: "password",
            label: "新しいパスワード",
            name: "password-new",
          },
          model: {
            value: _vm.passwordNew,
            callback: function ($$v) {
              _vm.passwordNew = $$v
            },
            expression: "passwordNew",
          },
        }),
        _c("text-field", {
          attrs: {
            type: "password",
            label: "新しいパスワード（確認）",
            name: "password-new-confirm",
          },
          model: {
            value: _vm.passwordNewConfirm,
            callback: function ($$v) {
              _vm.passwordNewConfirm = $$v
            },
            expression: "passwordNewConfirm",
          },
        }),
        _vm.message
          ? _c("div", { staticClass: "info" }, [_vm._v(_vm._s(_vm.message))])
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "button",
            attrs: {
              disabled:
                _vm.processing ||
                !_vm.passwordOld ||
                !_vm.passwordNew ||
                !_vm.passwordNewConfirm,
            },
            on: { click: _vm.submit },
          },
          [_vm._v(" 変更する ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(" 下記のフォームよりパスワードの変更を行なってください。"),
      _c("br"),
      _vm._v(" なお、パスワードには、英字大文字が1つ以上必要です。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }