var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("cupertino-alert-dialog", {
    attrs: { title: _vm.title, close: _vm.onClickOutSide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "px-1 py-1" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.value,
                    expression: "value",
                  },
                ],
                staticClass: "box-border w-full px-1/2 py-1/2",
                domProps: { value: _vm.value },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.value = $event.target.value
                  },
                },
              }),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex flex-row flex-center flex-spacearound" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full flex flex-center pointer py-1 hover left-bottom-radius-10 font-bold",
                    on: { click: _vm.onClickClose },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.close))])]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full flex flex-center pointer py-1 hover right-bottom-radius-10 font-bold",
                    on: { click: _vm.onClickSubmit },
                  },
                  [
                    _c("span", { staticClass: "color-blue" }, [
                      _vm._v(_vm._s(_vm.submit)),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }