var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "contest-detail" },
    [
      _vm.isLoading
        ? _c("vue-loading", {
            attrs: {
              type: "spiningDubbles",
              color: "#efad4c",
              size: { width: "50px", height: "50px" },
            },
          })
        : [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "image-container" }, [
                _c("img", {
                  attrs: {
                    src: _vm.image,
                    alt: "image",
                    width: "100%",
                    height: "auto",
                  },
                }),
              ]),
              _vm.referenceSource
                ? _c("div", { staticClass: "reference-source" }, [
                    _vm._v(_vm._s(`引用元：${_vm.referenceSource}`)),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "text-container" }, [
                _c("div", {
                  staticClass: "content",
                  domProps: { innerHTML: _vm._s(_vm.$sanitize(_vm.content)) },
                }),
              ]),
              _c("img", {
                staticClass: "cat-hr",
                attrs: {
                  src: require("@/assets/img/event/contest/cat_hr.png"),
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "side-container" },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _vm.organizer
                  ? _c("div", { staticClass: "organizer" }, [
                      _vm._v(_vm._s(_vm.organizer)),
                    ])
                  : _vm._e(),
                _vm.free
                  ? _c("div", { staticClass: "free" }, [
                      _vm._v(_vm._s(_vm.free)),
                    ])
                  : _vm._e(),
                _vm.themeName
                  ? [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("募集テーマ"),
                      ]),
                      _c("div", { staticClass: "summary" }, [
                        _vm._v(" " + _vm._s(_vm.themeName) + " "),
                      ]),
                    ]
                  : _vm._e(),
                _c("div", { staticClass: "label" }, [_vm._v("応募締切")]),
                _c("div", { staticClass: "summary" }, [
                  _vm._v(" " + _vm._s(_vm.deadline) + " "),
                ]),
                _vm.entryForm || _vm.contestId
                  ? _c(
                      "button",
                      {
                        staticClass: "apply-button",
                        attrs: { disabled: _vm.isExpired },
                        on: { click: _vm.onClickEntry },
                      },
                      [_vm._v(" 作品を応募する ")]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }