var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "item", on: { click: _vm.onClick } }, [
    _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "image-container" }, [
        _c(
          "div",
          { staticClass: "image", class: [_vm.bgColor] },
          [
            _vm.iconType === "Bookmark"
              ? _c("BookmarkIcon", {
                  staticClass: "image-item",
                  attrs: { fillColor: _vm.iconColor },
                })
              : _vm._e(),
            _vm.iconType === "Account"
              ? _c("AccountIcon", {
                  staticClass: "image-item",
                  attrs: { fillColor: _vm.iconColor },
                })
              : _vm._e(),
            _vm.iconType === "EmailOutline"
              ? _c("EmailOutlineIcon", {
                  staticClass: "image-item",
                  attrs: { fillColor: _vm.iconColor },
                })
              : _vm._e(),
            _vm.iconType === "Bullhorn"
              ? _c("BullhornIcon", {
                  staticClass: "image-item",
                  attrs: { fillColor: _vm.iconColor },
                })
              : _vm._e(),
            _vm.iconType === "CommentTextOutline"
              ? _c("CommentTextOutlineIcon", {
                  staticClass: "image-item",
                  attrs: { fillColor: _vm.iconColor },
                })
              : _vm._e(),
            _vm.iconType === "ShoePrint"
              ? _c("ShoePrintIcon", {
                  staticClass: "image-item",
                  attrs: { fillColor: _vm.iconColor },
                })
              : _vm._e(),
            _vm.iconType === "ThumbUp"
              ? _c("ThumbUpIcon", {
                  staticClass: "image-item",
                  attrs: { fillColor: _vm.iconColor },
                })
              : _vm._e(),
            _vm.iconType === "FlagVariant"
              ? _c("FlagVariantIcon", {
                  staticClass: "image-item",
                  attrs: { fillColor: _vm.iconColor },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "container", class: { isRead: _vm.isRead } }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.text))]),
        _c("div", { staticClass: "date" }, [
          _vm._v(_vm._s(_vm.formatDatetime)),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "right" },
      [
        _c("chevron-right-icon", {
          staticClass: "icon",
          attrs: { fillColor: "#C9C9C9" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }