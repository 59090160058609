var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("aside", { staticClass: "payment-description" }, [
      _c("div", [_vm._v(_vm._s(_vm.planName))]),
      _vm.loaded
        ? _c(
            "div",
            [
              _vm.plan === 0
                ? [
                    _vm.invoiceUrl
                      ? [
                          _c("div", { staticClass: "icon" }, [_vm._v("！")]),
                          _c(
                            "a",
                            {
                              staticClass: "subscription-info danger",
                              attrs: { href: _vm.invoiceUrl, target: "blank" },
                            },
                            [_vm._v(" 決済エラーが発生しています ＞ ")]
                          ),
                        ]
                      : [
                          _c(
                            "router-link",
                            {
                              staticClass: "subscription-info change",
                              attrs: { to: { name: "subscriptionAnnounce" } },
                            },
                            [_vm._v(" プランの変更 ＞ ")]
                          ),
                        ],
                  ]
                : _vm.isSchoolPlan
                ? [
                    _c(
                      "a",
                      {
                        staticClass: "subscription-info school-account-terms",
                        attrs: {
                          href: "/schoolAccountTerms",
                          target: "_blank",
                        },
                      },
                      [_vm._v(" スクールアカウント利用規約 ＞ ")]
                    ),
                  ]
                : [
                    _c(
                      "router-link",
                      {
                        staticClass: "subscription-info detail",
                        attrs: { to: { name: "subscriptionModify" } },
                      },
                      [_vm._v(" 詳細を見る ＞ ")]
                    ),
                  ],
            ],
            2
          )
        : _vm._e(),
    ]),
    _vm.invoiceUrl
      ? _c("div", { staticClass: "error-message" }, [
          _c("div", [_vm._v("決済エラーで支払いが完了していません。")]),
          _c("div", [
            _vm._v(" 再決済を "),
            _c("a", { attrs: { href: _vm.invoiceUrl, target: "blank" } }, [
              _vm._v("こちら"),
            ]),
            _vm._v(" からお願いします。 "),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }