var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "router-link",
    { staticClass: "memo-link", attrs: { to: { name: "memo" } } },
    [_c("document-outline-icon"), _c("span", [_vm._v("創作メモ")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }