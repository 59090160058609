var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "header" }, [
    _c(
      "div",
      {
        staticClass: "header-item",
        class: { active: _vm.isActiveTab(_vm.tabs.theme) },
        on: {
          click: function ($event) {
            return _vm.onChangeTab(_vm.tabs.theme)
          },
        },
      },
      [_vm._v(" 募集テーマ別 ")]
    ),
    _c(
      "div",
      {
        staticClass: "header-item",
        class: { active: _vm.isActiveTab(_vm.tabs.contest) },
        on: {
          click: function ($event) {
            return _vm.onChangeTab(_vm.tabs.contest)
          },
        },
      },
      [_vm._v(" コンテスト ")]
    ),
    _c(
      "div",
      {
        staticClass: "header-item",
        class: { active: _vm.isActiveTab(_vm.tabs.company) },
        on: {
          click: function ($event) {
            return _vm.onChangeTab(_vm.tabs.company)
          },
        },
      },
      [_vm._v(" 編集部の掲示板 ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }