var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.fetched
    ? _c(
        "div",
        { staticClass: "entry-profile" },
        [
          _c("h2", [_vm._v("応募者情報")]),
          _vm._m(0),
          _c("ContestEntryUserForm", {
            attrs: {
              cognitoUserEmail: _vm.cognitoUserEmail,
              errors: _vm.errors,
              isEdit: "",
            },
            model: {
              value: _vm.entryUser,
              callback: function ($$v) {
                _vm.entryUser = $$v
              },
              expression: "entryUser",
            },
          }),
          _c("div", { staticClass: "footer" }, [
            _c("button", { on: { click: _vm.onClickButton } }, [
              _vm._v(_vm._s(_vm.buttonText)),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "description" }, [
      _vm._v(
        " コンテストの応募時に必要な応募者情報をあらかじめ登録することで、応募時に入力を省略できます。"
      ),
      _c("br"),
      _vm._v(
        " なお、登録情報を変更しても、応募済みの応募者情報は変更されませんのでご安心ください。 "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }