var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "item" }, [
    _c("div", { staticClass: "image" }, [
      _c("div", { staticClass: "kind", class: { [_vm.item.dataType]: true } }, [
        _vm._v(_vm._s(_vm.kind)),
      ]),
      _c("img", { staticClass: "image-item", attrs: { src: _vm.image } }),
    ]),
    _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "left" }, [
        _c("div", [
          _c("div", { staticClass: "theme-title" }, [_vm._v("募集テーマ")]),
          _c("div", { staticClass: "theme", style: { color: _vm.color } }, [
            _vm._v(_vm._s(_vm.theme)),
          ]),
          _c("div", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.description)),
          ]),
        ]),
        _vm.isContest
          ? _c("div", [
              _c("div", { staticClass: "award" }, [_vm._v(_vm._s(_vm.award))]),
              _c("div", { staticClass: "deadline" }, [
                _vm._v("締切：" + _vm._s(_vm.deadline)),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "owner-container" }, [
          _c("div", { staticClass: "owner-title" }, [_vm._v("主催")]),
          _c("div", { staticClass: "owner" }, [_vm._v(_vm._s(_vm.owner))]),
        ]),
        _c("div", { staticClass: "button-container" }, [
          _c(
            "button",
            {
              staticClass: "button primary",
              on: { click: _vm.onClickPrimary },
            },
            [_c("div", [_vm._v("詳しく見る")])]
          ),
          _c(
            "button",
            {
              staticClass: "button secondary",
              on: { click: _vm.onClickSecondary },
            },
            [_c("div", [_vm._v("応募作品を執筆する")])]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }