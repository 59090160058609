var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "empty-list" }, [
    _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    _c("div", { staticClass: "message" }, [_vm._v(_vm._s(_vm.message))]),
    _c(
      "button",
      { staticClass: "button primary", on: { click: _vm.onClickButton } },
      [_vm._v(_vm._s(_vm.actionName))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }