import { render, staticRenderFns } from "./InputTextV2.vue?vue&type=template&id=32148ca6&scoped=true"
import script from "./InputTextV2.vue?vue&type=script&lang=ts"
export * from "./InputTextV2.vue?vue&type=script&lang=ts"
import style0 from "./InputTextV2.vue?vue&type=style&index=0&id=32148ca6&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32148ca6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/yamashitatakanori/project/web-studio-yhawk/indent/nola-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32148ca6')) {
      api.createRecord('32148ca6', component.options)
    } else {
      api.reload('32148ca6', component.options)
    }
    module.hot.accept("./InputTextV2.vue?vue&type=template&id=32148ca6&scoped=true", function () {
      api.rerender('32148ca6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/atoms/InputTextV2.vue"
export default component.exports