var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("EventHeader"),
      _c(
        "div",
        { staticClass: "body" },
        [
          _vm.isLoading
            ? _c("vue-loading", {
                attrs: {
                  type: "spiningDubbles",
                  color: "#efad4c",
                  size: { width: "50px", height: "50px" },
                },
              })
            : [
                _c("div", { staticClass: "title" }, [
                  _vm._v("現在募集中の作品テーマ"),
                ]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(
                    " 各編集部が募集するテーマや作品像を確認して、作品の執筆＆応募をしてみませんか？"
                  ),
                  _c("br"),
                  _vm._v(
                    "商業化作品として選ばれると、プロの作家としてのデビューにつながります。 "
                  ),
                ]),
                _vm.items.length
                  ? _c(
                      "div",
                      [
                        _vm._l(_vm.items, function (item) {
                          return [
                            _c("EventThemeItem", {
                              key: item.id,
                              attrs: { item: item },
                            }),
                          ]
                        }),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "empty-card" }, [
                      _vm._v("該当する募集テーマがありません"),
                    ]),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }