import { render, staticRenderFns } from "./NolaNovelEmptyList.vue?vue&type=template&id=42081897&scoped=true"
import script from "./NolaNovelEmptyList.vue?vue&type=script&lang=ts"
export * from "./NolaNovelEmptyList.vue?vue&type=script&lang=ts"
import style0 from "./NolaNovelEmptyList.vue?vue&type=style&index=0&id=42081897&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42081897",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/yamashitatakanori/project/web-studio-yhawk/indent/nola-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42081897')) {
      api.createRecord('42081897', component.options)
    } else {
      api.reload('42081897', component.options)
    }
    module.hot.accept("./NolaNovelEmptyList.vue?vue&type=template&id=42081897&scoped=true", function () {
      api.rerender('42081897', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/NolaNovelEmptyList.vue"
export default component.exports