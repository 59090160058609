var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("EventHeader"),
      _c(
        "div",
        { staticClass: "body" },
        [
          _vm.isLoading
            ? _c("vue-loading", {
                attrs: {
                  type: "spiningDubbles",
                  color: "#efad4c",
                  size: { width: "50px", height: "50px" },
                },
              })
            : [
                _c("div", { staticClass: "contest-list" }, [
                  _vm.sortedContests.length
                    ? _c(
                        "div",
                        { staticClass: "card-list" },
                        _vm._l(_vm.sortedContests, function (contest) {
                          return _c("contest-card", {
                            key: contest.id,
                            attrs: { contest: contest },
                          })
                        }),
                        1
                      )
                    : _c("div", { staticClass: "empty-card" }, [
                        _vm._v("該当するコンテストがありません"),
                      ]),
                ]),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }