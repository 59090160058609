var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: { "side-menu-novel-root": true, shrink: !_vm.expand },
      on: { click: _vm.submit },
    },
    [
      _vm.novel
        ? _c("div", [
            _vm.expand ? _c("div", { staticClass: "band" }) : _vm._e(),
            _c(
              "div",
              { staticClass: "grid-container", class: { shrink: !_vm.expand } },
              [
                _c(
                  "transition",
                  { attrs: { name: "fade-fast" } },
                  [
                    _c("image-view", {
                      class: { "novel-image": true, shrink: !_vm.expand },
                      attrs: {
                        src: _vm.imageKey,
                        placeholder: "/img/placeholders/novel.png",
                      },
                    }),
                  ],
                  1
                ),
                _vm.expand
                  ? _c(
                      "div",
                      {
                        staticClass: "novel-title",
                        class: { shrink: !_vm.expand },
                      },
                      [_vm._v(" " + _vm._s(_vm.novel.title) + " ")]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "edit-text", class: { shrink: !_vm.expand } },
                  [_vm._v("編集する")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }