var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "contest-row", on: { click: _vm.onClickRow } },
    [
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("div", { staticClass: "free" }, [_vm._v(_vm._s(_vm.free))]),
      ]),
      _c("div", { staticClass: "sub" }, [
        _c("div", { staticClass: "deadline" }, [
          _vm._v(_vm._s(`締切：${_vm.deadline}`)),
        ]),
        _c(
          "div",
          {
            staticClass: "remaining-days",
            class: { red: _vm.isDeadlineApproaching },
          },
          [
            _c("span", [_vm._v("あと")]),
            _c("span", { staticClass: "bold" }, [
              _vm._v(_vm._s(_vm.remainingDays)),
            ]),
            _c("span", [_vm._v("日")]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }