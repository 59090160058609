var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.expand
    ? _c(
        "div",
        {},
        [
          _c("div", { staticClass: "link", on: { click: _vm.onClick } }, [
            _c("span", { staticClass: "link-text" }, [
              _vm._v(" " + _vm._s(_vm.text) + " "),
            ]),
            !_vm.isEditing
              ? _c(
                  "div",
                  { staticClass: "edit-icon", class: { show: _vm.isOpen } },
                  [
                    _c("playlist-edit-icon", {
                      staticClass: "edit",
                      attrs: { fillColor: "#474a4d" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.onEditIconClick.apply(null, arguments)
                        },
                      },
                    }),
                    _c("delete-icon", {
                      staticClass: "edit",
                      attrs: { fillColor: "#474a4d" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.onDeleteIconClick.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isEditing
              ? _c(
                  "span",
                  {
                    staticClass: "edit-text",
                    class: { show: _vm.isOpen },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.onCancelClick.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" キャンセル ")]
                )
              : _vm._e(),
          ]),
          _c("expanded-item", { attrs: { isOpen: _vm.isOpen } }, [
            _c(
              "div",
              { staticClass: "material-container" },
              [
                _c("sortable-list", {
                  attrs: {
                    items: _vm.reorderableListItems,
                    keyAttr: "id",
                    disabled: !_vm.isEditing,
                    onReorder: _vm.onReorder,
                    handleClassName: ".drag-icon",
                  },
                  on: {
                    "update:items": function ($event) {
                      _vm.reorderableListItems = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "link-shrink",
                                class: { active: _vm.isActive(item) },
                                on: {
                                  click: function ($event) {
                                    return _vm.onLinkClick(item)
                                  },
                                },
                              },
                              [
                                _vm.showDeleteCheckbox(item)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "sidemenu-icon-shrink delete",
                                      },
                                      [
                                        _vm.checkedItems(item)
                                          ? _c("checkbox-marked-outline-icon", {
                                              staticClass: "center pointer",
                                              attrs: { size: 20 },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.onCheck(item)
                                                },
                                              },
                                            })
                                          : _c("checkbox-blank-outline-icon", {
                                              staticClass: "center pointer",
                                              attrs: { size: 20 },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.onCheck(item)
                                                },
                                              },
                                            }),
                                      ],
                                      1
                                    )
                                  : _c("img", {
                                      staticClass: "sidemenu-icon-shrink",
                                      attrs: { src: item.icon, alt: item.text },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDeleteIconClick(item)
                                        },
                                      },
                                    }),
                                _vm.showTextInput(item)
                                  ? _c("input", {
                                      staticClass: "link-text link-text-shrink",
                                      attrs: {
                                        type: "text",
                                        id: item.id,
                                        maxlength: "20",
                                      },
                                      domProps: { value: item.text },
                                      on: { input: _vm.onInput },
                                    })
                                  : _c(
                                      "span",
                                      {
                                        staticClass:
                                          "link-text link-text-shrink",
                                      },
                                      [_vm._v(_vm._s(item.text))]
                                    ),
                                _c("img", {
                                  staticClass: "drag-icon",
                                  class: { edit: _vm.isListEditing },
                                  attrs: {
                                    src: require("@/assets/img/icon/drag.png"),
                                  },
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    741493696
                  ),
                }),
                _c(
                  "div",
                  {
                    staticClass: "link-shrink",
                    class: { active: _vm.isActive(_vm.themeItem) },
                    on: {
                      click: function ($event) {
                        return _vm.onLinkClick(_vm.themeItem)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "sidemenu-icon-shrink",
                      attrs: {
                        src: _vm.themeItem.icon,
                        alt: _vm.themeItem.text,
                      },
                    }),
                    _c("span", { staticClass: "link-text link-text-shrink" }, [
                      _vm._v(_vm._s(_vm.themeItem.text)),
                    ]),
                  ]
                ),
                !_vm.isEditing
                  ? _c(
                      "div",
                      {
                        staticClass: "add-text center",
                        class: { show: _vm.isOpen },
                        attrs: { alt: "新しい資料種別を追加する" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onAddButtonClick.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(" ＋ 新規の資料種別を追加する ")]
                    )
                  : _vm._e(),
                _vm.showUpdateButton
                  ? _c(
                      "div",
                      {
                        staticClass: "add-text center",
                        class: { show: _vm.isOpen },
                        attrs: { alt: "資料種別を更新する" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onUpdateButtonClick.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _c("playlist-edit-icon", {
                          staticClass: "center",
                          attrs: { size: 20 },
                        }),
                        _vm._v(" 変更を保存する "),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showDeleteButton
                  ? _c(
                      "div",
                      {
                        staticClass: "add-text center delete",
                        class: { show: _vm.isOpen },
                        attrs: { alt: "資料種別の削除" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onDeleteButtonClick.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _c("delete-icon", {
                          staticClass: "center",
                          attrs: { size: 20 },
                        }),
                        _c("span", { staticClass: "center" }, [
                          _vm._v(
                            "削除する（" +
                              _vm._s(_vm.selectedItems.length) +
                              "）"
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "closed",
          on: { mouseover: _vm.onMouseOver, mouseleave: _vm.onMouseLeave },
        },
        [
          _c(
            "div",
            {
              staticClass: "link",
              class: { shrink: true, active: _vm.isCurrent },
              on: { click: () => _vm.onLinkClick() },
            },
            [
              _c("img", {
                staticClass: "sidemenu-icon",
                class: { shrink: true },
                attrs: { src: _vm.icon, alt: _vm.text },
              }),
            ]
          ),
          _vm.isMouseHover
            ? _c("div", { staticClass: "float-menu" }, [
                _c("div", { staticClass: "header" }, [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.text)),
                  ]),
                  !_vm.isEditing
                    ? _c(
                        "div",
                        {
                          staticClass: "edit-icon",
                          class: { show: _vm.isOpen },
                        },
                        [
                          _c("playlist-edit-icon", {
                            staticClass: "edit",
                            attrs: { fillColor: "#474a4d" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onEditIconClick.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          }),
                          _c("delete-icon", {
                            staticClass: "edit",
                            attrs: { fillColor: "#474a4d" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onDeleteIconClick.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isEditing
                    ? _c(
                        "span",
                        {
                          staticClass: "edit-text",
                          class: { show: _vm.isOpen },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCancelClick.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" キャンセル ")]
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "material-container" },
                  [
                    _c("sortable-list", {
                      attrs: {
                        items: _vm.reorderableListItems,
                        keyAttr: "id",
                        disabled: !_vm.isEditing,
                        onReorder: _vm.onReorder,
                        handleClassName: ".drag-icon",
                      },
                      on: {
                        "update:items": function ($event) {
                          _vm.reorderableListItems = $event
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "link-shrink float-menu-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onLinkClick(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm.showDeleteCheckbox(item)
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "sidemenu-icon-shrink delete",
                                          },
                                          [
                                            _vm.checkedItems(item)
                                              ? _c(
                                                  "checkbox-marked-outline-icon",
                                                  {
                                                    staticClass:
                                                      "center pointer",
                                                    attrs: { size: 20 },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.onCheck(item)
                                                      },
                                                    },
                                                  }
                                                )
                                              : _c(
                                                  "checkbox-blank-outline-icon",
                                                  {
                                                    staticClass:
                                                      "center pointer",
                                                    attrs: { size: 20 },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.onCheck(item)
                                                      },
                                                    },
                                                  }
                                                ),
                                          ],
                                          1
                                        )
                                      : _c("img", {
                                          staticClass: "sidemenu-icon-shrink",
                                          attrs: {
                                            src: item.icon,
                                            alt: item.text,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDeleteIconClick(item)
                                            },
                                          },
                                        }),
                                    _vm.showTextInput(item)
                                      ? _c("input", {
                                          staticClass:
                                            "link-text link-text-shrink",
                                          attrs: {
                                            type: "text",
                                            id: item.id,
                                            maxlength: "20",
                                          },
                                          domProps: { value: item.text },
                                          on: { input: _vm.onInput },
                                        })
                                      : _c(
                                          "span",
                                          {
                                            staticClass:
                                              "link-text link-text-shrink item-text",
                                          },
                                          [_vm._v(_vm._s(item.text))]
                                        ),
                                    _c("img", {
                                      staticClass: "drag-icon",
                                      class: { edit: _vm.isListEditing },
                                      attrs: {
                                        src: require("@/assets/img/icon/drag.png"),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        602971129
                      ),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "link-shrink float-menu-item",
                        class: { active: _vm.isActive(_vm.themeItem) },
                        on: {
                          click: function ($event) {
                            return _vm.onLinkClick(_vm.themeItem)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "sidemenu-icon-shrink",
                          attrs: {
                            src: _vm.themeItem.icon,
                            alt: _vm.themeItem.text,
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "link-text link-text-shrink item-text",
                          },
                          [_vm._v(_vm._s(_vm.themeItem.text))]
                        ),
                      ]
                    ),
                    !_vm.isEditing
                      ? _c(
                          "div",
                          {
                            staticClass: "add-text center float-menu-item",
                            class: { show: _vm.isOpen },
                            attrs: { alt: "新しい資料種別を追加する" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onAddButtonClick.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._v(" ＋ 新規の資料種別を追加する ")]
                        )
                      : _vm._e(),
                    _vm.showUpdateButton
                      ? _c(
                          "div",
                          {
                            staticClass: "add-text center float-menu-item",
                            class: { show: _vm.isOpen },
                            attrs: { alt: "資料種別を更新する" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onUpdateButtonClick.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("playlist-edit-icon", {
                              staticClass: "center",
                              attrs: { size: 20 },
                            }),
                            _vm._v(" 変更を保存する "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showDeleteButton
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "add-text center delete float-menu-item",
                            class: { show: _vm.isOpen },
                            attrs: { alt: "資料種別の削除" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onDeleteButtonClick.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("delete-icon", {
                              staticClass: "center",
                              attrs: { size: 20 },
                            }),
                            _c("span", { staticClass: "center" }, [
                              _vm._v(
                                "削除する（" +
                                  _vm._s(_vm.selectedItems.length) +
                                  "）"
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }