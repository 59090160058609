var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "ContestEntryItem",
        {
          staticClass: "contest-entry-item",
          attrs: {
            name: "お名前",
            required: _vm.isConfirm || _vm.isEdit ? undefined : true,
            error: _vm.errorMessage("name"),
          },
        },
        [
          _c("InputTextV2", {
            attrs: {
              type: "text",
              placeholder: "お名前を入力してください",
              maxlength: 100,
              isDisplayCount: !_vm.isConfirm,
              readonly: _vm.isConfirm,
              error: !!_vm.errorMessage("name"),
            },
            model: {
              value: _vm.internalValue.name,
              callback: function ($$v) {
                _vm.$set(_vm.internalValue, "name", $$v)
              },
              expression: "internalValue.name",
            },
          }),
        ],
        1
      ),
      _c(
        "ContestEntryItem",
        {
          staticClass: "contest-entry-item",
          attrs: {
            name: "お名前（ふりがな）",
            description: _vm.isConfirm
              ? undefined
              : "ひらがなで入力してください。",
            required: _vm.isConfirm || _vm.isEdit ? undefined : true,
            error: _vm.errorMessage("nameKana"),
          },
        },
        [
          _c("InputTextV2", {
            attrs: {
              type: "text",
              placeholder: "お名前（ふりがな）を入力してください",
              maxlength: 100,
              isDisplayCount: !_vm.isConfirm,
              readonly: _vm.isConfirm,
              error: !!_vm.errorMessage("nameKana"),
            },
            model: {
              value: _vm.internalValue.nameKana,
              callback: function ($$v) {
                _vm.$set(_vm.internalValue, "nameKana", $$v)
              },
              expression: "internalValue.nameKana",
            },
          }),
        ],
        1
      ),
      _c(
        "ContestEntryItem",
        {
          staticClass: "contest-entry-item",
          attrs: {
            name: "ペンネーム",
            required: _vm.isConfirm || _vm.isEdit ? undefined : true,
            description: _vm.isConfirm
              ? undefined
              : "ペンネームを「＋追加する」から登録できます。",
            error: _vm.errorMessage("penname"),
          },
        },
        [
          !_vm.isEdit
            ? [
                _c("SelectBoxV2", {
                  attrs: {
                    options: _vm.pennameOptions,
                    placeholder: "ペンネームを選択してください",
                    readonly: _vm.isConfirm,
                    error: !!_vm.errorMessage("penname"),
                  },
                  model: {
                    value: _vm.internalValue.penname,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalValue, "penname", $$v)
                    },
                    expression: "internalValue.penname",
                  },
                }),
              ]
            : [
                _vm.contestUser
                  ? _c(
                      "div",
                      { staticClass: "tag-list" },
                      _vm._l(_vm.contestUser.penname, function (penname) {
                        return _c("TagItem", {
                          key: penname,
                          attrs: { text: penname, readonly: _vm.isConfirm },
                          on: {
                            delete: function ($event) {
                              return _vm.onClickDeletePenname(penname)
                            },
                          },
                        })
                      }),
                      1
                    )
                  : _c("div", { staticClass: "no-create" }, [_vm._v("未作成")]),
              ],
          !_vm.isConfirm
            ? _c(
                "button",
                {
                  staticClass: "add-penname",
                  on: { click: _vm.onClickAddPenname },
                },
                [_vm._v("＋追加する")]
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "ContestEntryItem",
        {
          staticClass: "contest-entry-item",
          attrs: {
            name: "メールアドレス",
            description:
              _vm.isConfirm || _vm.isEdit
                ? undefined
                : "Nolaアカウントで登録のアドレス以外を入力する場合は、「メールアドレス確認用」項目も入力してください。",
            required: _vm.isConfirm || _vm.isEdit ? undefined : true,
            error: _vm.errorMessage("email"),
          },
        },
        [
          _c("InputTextV2", {
            attrs: {
              type: "email",
              initialValue: _vm.cognitoUserEmail,
              placeholder: "メールアドレスを入力してください",
              maxlength: 100,
              isDisplayCount: !_vm.isConfirm,
              readonly: _vm.isConfirm,
              error: !!_vm.errorMessage("email"),
            },
            model: {
              value: _vm.internalValue.email,
              callback: function ($$v) {
                _vm.$set(_vm.internalValue, "email", $$v)
              },
              expression: "internalValue.email",
            },
          }),
        ],
        1
      ),
      _vm.internalValue.email !== _vm.cognitoUserEmail &&
      !_vm.isConfirm &&
      !_vm.isEdit
        ? _c(
            "ContestEntryItem",
            {
              staticClass: "contest-entry-item",
              attrs: {
                name: "メールアドレス確認用",
                required: _vm.isConfirm || _vm.isEdit ? undefined : true,
                error: _vm.errorMessage("emailConfirm"),
              },
            },
            [
              _c("InputTextV2", {
                attrs: {
                  type: "email",
                  placeholder: "メールアドレス確認用を入力してください",
                  maxlength: 100,
                  isDisplayCount: !_vm.isConfirm,
                  readonly: _vm.isConfirm,
                  error: !!_vm.errorMessage("emailConfirm"),
                },
                model: {
                  value: _vm.internalValue.emailConfirm,
                  callback: function ($$v) {
                    _vm.$set(_vm.internalValue, "emailConfirm", $$v)
                  },
                  expression: "internalValue.emailConfirm",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "ContestEntryItem",
        {
          staticClass: "contest-entry-item",
          attrs: {
            name: "電話番号",
            description: _vm.isConfirm
              ? undefined
              : "ハイフンなしの形式で入力してください。",
            required: _vm.isConfirm || _vm.isEdit ? undefined : true,
            error: _vm.errorMessage("phone"),
          },
        },
        [
          _c("InputTextV2", {
            attrs: {
              type: "tel",
              placeholder: "電話番号を入力してください",
              maxlength: 11,
              isDisplayCount: !_vm.isConfirm,
              readonly: _vm.isConfirm,
              error: !!_vm.errorMessage("phone"),
            },
            model: {
              value: _vm.internalValue.phone,
              callback: function ($$v) {
                _vm.$set(_vm.internalValue, "phone", $$v)
              },
              expression: "internalValue.phone",
            },
          }),
        ],
        1
      ),
      _c(
        "ContestEntryItem",
        {
          staticClass: "contest-entry-item",
          attrs: {
            name: "性別",
            required: _vm.isConfirm || _vm.isEdit ? undefined : true,
            error: _vm.errorMessage("gender"),
          },
        },
        [
          _c("SelectBoxV2", {
            attrs: {
              options: _vm.genderOptions,
              placeholder: "性別を選択してください",
              allowUnselected: _vm.isEdit,
              readonly: _vm.isConfirm,
              error: !!_vm.errorMessage("gender"),
            },
            model: {
              value: _vm.internalValue.gender,
              callback: function ($$v) {
                _vm.$set(_vm.internalValue, "gender", $$v)
              },
              expression: "internalValue.gender",
            },
          }),
        ],
        1
      ),
      _c(
        "ContestEntryItem",
        {
          staticClass: "contest-entry-item",
          attrs: {
            name: "生年月日",
            required: _vm.isConfirm || _vm.isEdit ? undefined : true,
            error: _vm.errorMessage("birthdate"),
          },
        },
        [
          _c("DatePicker", {
            attrs: {
              format: "yyyy/MM/dd",
              language: _vm.ja,
              placeholder: "生年月日を入力してください",
              typeable: "",
              disabled: _vm.isConfirm,
              inputClass: {
                "input-wrapper": true,
                "date-picker": true,
                error: !!_vm.errorMessage("birthdate"),
              },
            },
            model: {
              value: _vm.internalValue.birthdate,
              callback: function ($$v) {
                _vm.$set(_vm.internalValue, "birthdate", $$v)
              },
              expression: "internalValue.birthdate",
            },
          }),
        ],
        1
      ),
      _c(
        "ContestEntryItem",
        {
          staticClass: "contest-entry-item",
          attrs: {
            name: "応募歴・受賞歴",
            description: _vm.isConfirm
              ? undefined
              : "過去のコンテストの応募歴や受賞歴を入力してください。",
            required: _vm.isConfirm || _vm.isEdit ? undefined : false,
            error: _vm.errorMessage("historyOfAwards"),
          },
        },
        [
          _c("InputTextV2", {
            attrs: {
              rows: 5,
              placeholder: "応募歴・受賞歴を入力してください",
              maxlength: 500,
              isDisplayCount: !_vm.isConfirm,
              readonly: _vm.isConfirm,
              error: !!_vm.errorMessage("historyOfAwards"),
            },
            model: {
              value: _vm.internalValue.historyOfAwards,
              callback: function ($$v) {
                _vm.$set(_vm.internalValue, "historyOfAwards", $$v)
              },
              expression: "internalValue.historyOfAwards",
            },
          }),
        ],
        1
      ),
      !_vm.isEdit
        ? [
            !_vm.isConfirm && _vm.contest
              ? _c(
                  "ContestEntryItem",
                  {
                    staticClass: "contest-entry-item",
                    attrs: {
                      name: "応募規約",
                      description: _vm.isConfirm
                        ? undefined
                        : "本イベントの応募規約をご確認の上、ご応募をお願いいたします。",
                      required: _vm.isConfirm ? undefined : true,
                    },
                  },
                  [
                    _c("InputTextV2", {
                      attrs: {
                        rows: 20,
                        value: _vm.contest.terms,
                        readonly: "",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "input-wrapper terms" },
                      [
                        _c("CheckboxItem", {
                          attrs: { text: "応募規約に同意する" },
                          model: {
                            value: _vm.internalValue.isAgreedTerms,
                            callback: function ($$v) {
                              _vm.$set(_vm.internalValue, "isAgreedTerms", $$v)
                            },
                            expression: "internalValue.isAgreedTerms",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "ContestEntryItem",
              {
                staticClass: "contest-entry-item",
                attrs: {
                  name: "応募者情報の保存",
                  description: _vm.isConfirm
                    ? undefined
                    : "このページで入力した応募者情報を保存することで、次回のイベント応募の際に入力を省略できます。",
                  required: _vm.isConfirm ? undefined : false,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "input-wrapper",
                    class: { readonly: _vm.isConfirm },
                  },
                  [
                    !_vm.isConfirm
                      ? _c("CheckboxItem", {
                          attrs: { text: "入力した応募者情報を保存する" },
                          model: {
                            value: _vm.internalValue.isSaveEntryUser,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.internalValue,
                                "isSaveEntryUser",
                                $$v
                              )
                            },
                            expression: "internalValue.isSaveEntryUser",
                          },
                        })
                      : _c("div", [
                          _vm._v(
                            _vm._s(
                              `応募者情報を保存${
                                _vm.internalValue.isSaveEntryUser
                                  ? "する"
                                  : "しない"
                              }`
                            )
                          ),
                        ]),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }