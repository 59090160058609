var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "eventContestEntry", staticClass: "event-contest-entry" },
    [
      _vm.currentStep === _vm.Steps.EntryData
        ? [
            _c("ContestEntryDataForm", {
              attrs: {
                contest: _vm.contest,
                contestImage: _vm.contestImage,
                errors: _vm.errors,
              },
              model: {
                value: _vm.entryData,
                callback: function ($$v) {
                  _vm.entryData = $$v
                },
                expression: "entryData",
              },
            }),
            _vm.entryData.novel
              ? _c("div", { staticClass: "button-wrapper justify-end" }, [
                  _c(
                    "button",
                    {
                      staticClass: "next",
                      on: {
                        click: function ($event) {
                          return _vm.onClickTransition(_vm.Steps.EntryUser)
                        },
                      },
                    },
                    [_vm._v("応募者情報を入力 ＞")]
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.currentStep === _vm.Steps.EntryUser
        ? [
            _c("ContestEntryUserForm", {
              attrs: {
                initialValue: _vm.initialValueEntryUser,
                contest: _vm.contest,
                cognitoUserEmail: _vm.cognitoUserEmail,
                errors: _vm.errors,
              },
              model: {
                value: _vm.entryUser,
                callback: function ($$v) {
                  _vm.entryUser = $$v
                },
                expression: "entryUser",
              },
            }),
            _c("div", { staticClass: "button-wrapper justify-between" }, [
              _c(
                "button",
                {
                  staticClass: "back",
                  on: {
                    click: function ($event) {
                      return _vm.onClickTransition(_vm.Steps.EntryData)
                    },
                  },
                },
                [_vm._v("＜ 作品情報の入力")]
              ),
              _c(
                "button",
                {
                  staticClass: "next",
                  attrs: { disabled: !_vm.entryUser.isAgreedTerms },
                  on: {
                    click: function ($event) {
                      return _vm.onClickTransition(_vm.Steps.Confirm)
                    },
                  },
                },
                [_vm._v(" 応募内容の確認 ＞ ")]
              ),
            ]),
          ]
        : _vm._e(),
      _vm.currentStep === _vm.Steps.Confirm
        ? [
            _c("h2", [_vm._v("応募作品情報")]),
            _c("ContestEntryDataForm", {
              attrs: {
                contest: _vm.contest,
                contestImage: _vm.contestImage,
                isConfirm: true,
              },
              model: {
                value: _vm.entryData,
                callback: function ($$v) {
                  _vm.entryData = $$v
                },
                expression: "entryData",
              },
            }),
            _c("hr"),
            _c("h2", [_vm._v("応募者情報")]),
            _c("ContestEntryUserForm", {
              attrs: {
                contest: _vm.contest,
                cognitoUserEmail: _vm.cognitoUserEmail,
                isConfirm: true,
              },
              model: {
                value: _vm.entryUser,
                callback: function ($$v) {
                  _vm.entryUser = $$v
                },
                expression: "entryUser",
              },
            }),
            _c("div", { staticClass: "button-wrapper flex-col items-center" }, [
              _c(
                "button",
                {
                  staticClass: "submit",
                  on: {
                    click: function ($event) {
                      return _vm.onClickTransition(_vm.Steps.Complete)
                    },
                  },
                },
                [_vm._v("応募する")]
              ),
              _c(
                "button",
                {
                  staticClass: "edit",
                  on: {
                    click: function ($event) {
                      return _vm.onClickTransition(_vm.Steps.EntryUser)
                    },
                  },
                },
                [_vm._v("応募者情報の編集")]
              ),
            ]),
          ]
        : _vm._e(),
      _vm.currentStep === _vm.Steps.Complete
        ? [
            _c("ContestEntryComplete", { attrs: { contest: _vm.contest } }),
            _c("div", { staticClass: "button-wrapper flex-col items-center" }, [
              _c(
                "button",
                { staticClass: "submit", on: { click: _vm.onClickToTop } },
                [_vm._v("トップページに戻る")]
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }