var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "flex-container" }, [
    _c(
      "div",
      { staticClass: "info-banner" },
      [
        _vm._l(_vm.banner, function (bannerItem) {
          return [
            _c(
              "a",
              {
                key: bannerItem.id,
                attrs: { href: bannerItem.link, target: "blank" },
                on: {
                  click: function ($event) {
                    return _vm.ga(bannerItem.id)
                  },
                },
              },
              [_c("img", { attrs: { src: `${bannerItem.image.url}?w=600` } })]
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }