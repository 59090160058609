var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("CupertinoAlertDialog", {
    attrs: { title: _vm.title, close: _vm.onClickOutSide, width: 500 },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("div", { staticClass: "description" }, [
              _vm._v(_vm._s(_vm.description)),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "w-full flex flex-center flex-spacebetween box-border px-1 py-1",
              },
              [
                _c("div", [
                  _c("span", { staticClass: "font-bold font-large mr-small" }, [
                    _vm._v(_vm._s(_vm.selected.length) + "件"),
                  ]),
                  _c("span", [_vm._v("選択中")]),
                ]),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "button cancel font-bold mr-1",
                      on: { click: _vm.onClickDeselectAll },
                    },
                    [_vm._v("全件選択解除")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button primary font-bold",
                      on: { click: _vm.onClickSelectAll },
                    },
                    [_vm._v("全件選択")]
                  ),
                ]),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _vm._l(_vm.items, function (item) {
              return [
                _c(
                  "div",
                  {
                    key: item[_vm.itemsKeyAttribute],
                    staticClass: "item hover",
                    on: {
                      click: function ($event) {
                        return _vm.onClickItem(item)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "checkbox",
                        class: { checked: _vm.isChecked(item) },
                      },
                      [_vm._v(_vm._s(_vm.selectedOrder(item)))]
                    ),
                    _vm.type === "characters"
                      ? [
                          _c("CharacterItem", {
                            attrs: { novelId: _vm.novelId, character: item },
                          }),
                        ]
                      : _vm._e(),
                    _vm.type === "manuscripts"
                      ? [
                          _c("span", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(item.title || "（名称未設定）")),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex flex-row flex-center flex-spacearound" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full flex flex-center pointer py-1 hover bottom-radius-10 font-bold",
                    on: { click: _vm.onClickClose },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.close))])]
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }