var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "root" }, [
    _c("div", { staticClass: "header" }, [
      _c(
        "div",
        {
          staticClass: "header-item",
          class: { active: _vm.isActiveTab(_vm.tabs.all) },
          on: {
            click: function ($event) {
              return _vm.onClickTab(_vm.tabs.all)
            },
          },
        },
        [_vm._v("すべて")]
      ),
      _c(
        "div",
        {
          staticClass: "header-item",
          class: { active: _vm.isActiveTab(_vm.tabs.reader) },
          on: {
            click: function ($event) {
              return _vm.onClickTab(_vm.tabs.reader)
            },
          },
        },
        [_vm._v(" 読者より ")]
      ),
      _c(
        "div",
        {
          staticClass: "header-item",
          class: { active: _vm.isActiveTab(_vm.tabs.editor) },
          on: {
            click: function ($event) {
              return _vm.onClickTab(_vm.tabs.editor)
            },
          },
        },
        [_vm._v(" 編集者より ")]
      ),
      _c(
        "div",
        {
          staticClass: "header-item",
          class: { active: _vm.isActiveTab(_vm.tabs.another) },
          on: {
            click: function ($event) {
              return _vm.onClickTab(_vm.tabs.another)
            },
          },
        },
        [_vm._v(" その他 ")]
      ),
    ]),
    _c("div", { staticClass: "body" }, [
      _vm.isNoData
        ? _c("div", { staticClass: "empty" }, [_vm._v("通知はありません")])
        : _c("div", [
            _c(
              "div",
              { staticClass: "list" },
              [
                _vm._l(
                  _vm.filteredUnreadNotifications,
                  function (notification) {
                    return _c("NolaNovelNotificationListItem", {
                      key: notification.typeTargetIdSubTargetId,
                      staticClass: "list-item",
                      attrs: { notification: notification },
                    })
                  }
                ),
                !_vm.isCompleteUnread
                  ? _c("InfiniteLoading", {
                      on: { infinite: _vm.infiniteUnreadHandler },
                    })
                  : _vm._e(),
                _vm.isCompleteUnread
                  ? _c(
                      "div",
                      [
                        _vm._l(
                          _vm.filteredReadNotifications,
                          function (notification) {
                            return _c("NolaNovelNotificationListItem", {
                              key: notification.typeTargetIdSubTargetId,
                              staticClass: "list-item",
                              attrs: { notification: notification },
                            })
                          }
                        ),
                        !_vm.isCompleteRead
                          ? _c("InfiniteLoading", {
                              on: { infinite: _vm.infiniteReadHandler },
                            })
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }