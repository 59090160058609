var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "entered-contests" },
    [
      _vm._l(_vm.contestEntries, function (contest) {
        return [
          _c(
            "div",
            {
              key: contest.contestId,
              staticClass: "contest",
              on: {
                click: function ($event) {
                  return _vm.onClickContest(contest.contestId)
                },
              },
            },
            [
              _c("div", { staticClass: "contest-data" }, [
                _c("div", { staticClass: "left" }, [
                  _c("img", {
                    staticClass: "contest-image",
                    attrs: {
                      src: _vm.contestImage(contest.contestId),
                      alt: "contest image",
                    },
                  }),
                  _c("div", { staticClass: "contest-name" }, [
                    _vm._v(_vm._s(contest.contestName)),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("div", { staticClass: "entry-count" }, [
                      _vm._v(_vm._s(`${contest.entryNovels.length}作品応募`)),
                    ]),
                    _c("ChevronDownIcon", {
                      staticClass: "caret",
                      class: { open: _vm.isOpen(contest.contestId) },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.isOpen(contest.contestId)
                ? _c(
                    "div",
                    {
                      key: `${contest.contestId}_novels`,
                      staticClass: "entry-novels",
                    },
                    [
                      _c("div", { staticClass: "label-title" }, [
                        _vm._v("応募済みの作品"),
                      ]),
                      _vm._l(contest.entryNovels, function (novel) {
                        return [
                          _c(
                            "div",
                            {
                              key: novel.entryId,
                              staticClass: "novel",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.onClickNovel(novel.novelId)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "novel-data" },
                                [
                                  _c("ImageView", {
                                    staticClass: "image",
                                    attrs: {
                                      src:
                                        _vm.novelImage(novel.novelId) ||
                                        _vm.placeholderImage,
                                    },
                                  }),
                                  _c("div", { staticClass: "data" }, [
                                    _c("div", { staticClass: "category" }, [
                                      _vm._v(_vm._s(novel.categoryName)),
                                    ]),
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(
                                        _vm._s(_vm.novelTitle(novel.novelId))
                                      ),
                                    ]),
                                    _c("div", { staticClass: "information" }, [
                                      _c("div", { staticClass: "status" }, [
                                        _vm._v(_vm._s(novel.entryStatusText)),
                                      ]),
                                      _c("div", { staticClass: "date" }, [
                                        _vm._v(
                                          _vm._s(
                                            `応募日：${_vm.formatDate(
                                              novel.entryTime
                                            )}`
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "caution" }, [
                                _vm._v(
                                  "※ 表示は応募時の内容と異なる可能性があります。"
                                ),
                              ]),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
        ]
      }),
      _vm.contestEntries.length === 0
        ? _c("div", { staticClass: "no-entry" }, [
            _vm._v("応募した作品はありません"),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }