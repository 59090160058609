var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "link",
      class: { shrink: !_vm.expand, active: _vm.isCurrent },
      on: {
        click: _vm.onClick,
        mouseover: _vm.onMouseOver,
        mouseleave: _vm.onMouseLeave,
      },
    },
    [
      !_vm.expand
        ? _c("img", {
            staticClass: "sidemenu-icon",
            attrs: { src: _vm.icon, alt: _vm.text },
          })
        : _vm._e(),
      _c("transition", { attrs: { name: "fade-fast" } }, [
        _c(
          "span",
          { staticClass: "link-text", class: { shrink: !_vm.expand } },
          [_vm._v(" " + _vm._s(_vm.text) + " ")]
        ),
      ]),
      !_vm.expand && _vm.isMouseHover
        ? _c("div", { staticClass: "float-menu" }, [
            _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }