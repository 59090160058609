var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mypage" },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("h2", { staticClass: "h2" }, [_vm._v("マイページ")]),
          _vm._m(0),
          _c("text-field", {
            attrs: {
              label: "著者名",
              type: "text",
              placeholder: "ペンネームを入力してください",
              maxlength: 100,
              readonly: !_vm.loaded,
            },
            model: {
              value: _vm.userName,
              callback: function ($$v) {
                _vm.userName = $$v
              },
              expression: "userName",
            },
          }),
          _c("p", { staticClass: "title" }, [_vm._v("メールアドレス")]),
          _c("aside", [_vm._v(_vm._s(_vm.email))]),
          _c("p", { staticClass: "title" }, [_vm._v("ユーザー ID")]),
          _c("aside", [_vm._v(_vm._s(_vm.id))]),
          _c("p", { staticClass: "comment" }, [
            _vm._v(
              "※ユーザーIDは、お問い合わせの際などにご確認させていただく場合がございます。"
            ),
          ]),
          _c("p", { staticClass: "title" }, [_vm._v("登録状態")]),
          _c("payment-description"),
          _c(
            "div",
            [
              _c(
                "button-default",
                {
                  staticClass: "button-default",
                  attrs: { disabled: !_vm.changed },
                  on: { click: _vm.save },
                },
                [_vm._v("変更内容を保存する")]
              ),
            ],
            1
          ),
          !_vm.isTwitterLogin
            ? _c(
                "div",
                [
                  _c("h2", { staticClass: "h2" }, [_vm._v("パスワードの変更")]),
                  _c("p", [
                    _vm._v(
                      "ログイン時のパスワードの変更をご希望の際は、下記のボタンより変更対応を行ってください。"
                    ),
                  ]),
                  _c(
                    "router-link",
                    {
                      staticClass: "button cancel",
                      attrs: { to: { name: "changePassword" } },
                    },
                    [_vm._v("パスワード変更")]
                  ),
                  _c("h2", { staticClass: "h2" }, [
                    _vm._v("メールアドレスの変更"),
                  ]),
                  _c("p", [
                    _vm._v(
                      "ログイン時のメールアドレスの変更をご希望の際は、下記のボタンより変更対応を行ってください。"
                    ),
                  ]),
                  _c(
                    "router-link",
                    {
                      staticClass: "button cancel",
                      attrs: { to: { name: "changeEmail" } },
                    },
                    [_vm._v("メールアドレス変更")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("h2", { staticClass: "h2" }, [_vm._v("ログアウト")]),
          _c("p", [
            _vm._v("ログアウトをする際は、下記のボタンを押してください。"),
          ]),
          _c(
            "button",
            { staticClass: "button cancel", on: { click: _vm.logout } },
            [_vm._v("ログアウト")]
          ),
          _vm.isTwitterLogin
            ? _c(
                "div",
                [
                  _vm._m(1),
                  _vm._m(2),
                  _c(
                    "router-link",
                    {
                      staticClass: "button",
                      attrs: { to: { name: "migrate" } },
                    },
                    [_vm._v("メールアドレスを登録")]
                  ),
                  _c("p", { staticClass: "comment" }, [
                    _vm._v(
                      " ※ Twitterログインのみのご利用がサポート外になる場合は、前もってサービス内にてお知らせを行います。 "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          !_vm.isSchoolPlan
            ? _c("div", [
                _c("h2", { staticClass: "h2" }, [_vm._v("アカウント削除")]),
                _vm._m(3),
                _c(
                  "p",
                  { staticStyle: { color: "red" } },
                  [
                    _vm._v(
                      " ◎ データの引き継ぎ周りでの不具合の際は、アカウント削除前に必ず "
                    ),
                    _c("router-link", { attrs: { to: "/contact" } }, [
                      _vm._v("お問い合わせ"),
                    ]),
                    _vm._v("をお願いします。 "),
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "button danger",
                    class: { processing: _vm.isProcessing },
                    attrs: { disabled: _vm.isProcessing },
                    on: { click: _vm.onClickDeleteAccount },
                  },
                  [_vm._v(" アカウント削除 ")]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("site-footer", { staticClass: "footer" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(
        " 登録内容を変更する場合は、以下の項目の内容を編集し保存してください。"
      ),
      _c("br"),
      _vm._v(
        " 入力項目は保存する前にメモなどを取っておくことを推奨しています。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h2", { staticClass: "h2" }, [
      _c("img", {
        staticClass: "description-img",
        attrs: { src: require("@/assets/img/static/attention.png") },
      }),
      _vm._v("メールアドレスの登録"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(" Nolaはメールアドレスログインに移行を行いました。"),
      _c("br"),
      _vm._v(
        " Twitterログインのみでのご利用は今後サポート外となる恐れがありますので、メールアドレスの登録をお願いします。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(" 以下のボタンからアカウントを削除することができます。"),
      _c("br"),
      _vm._v(
        " アカウントを削除すると登録した全ての作品データ（執筆・プロット・登場人物など含む）が削除され、復元することはできませんのでご注意ください。 "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }