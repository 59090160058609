var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "character-item" },
    [
      _c("ImageView", {
        staticClass: "image",
        attrs: {
          src: _vm.image,
          placeholder: "/img/placeholders/character.png",
        },
      }),
      _c("div", [
        _c("div", { staticClass: "role" }, [
          _vm._v("役職：" + _vm._s(_vm.role)),
        ]),
        _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }