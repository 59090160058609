var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.expand
    ? _c(
        "div",
        {},
        [
          _c("div", { staticClass: "link", on: { click: _vm.onClick } }, [
            _c("span", { staticClass: "link-text" }, [
              _vm._v(" " + _vm._s(_vm.text) + " "),
            ]),
          ]),
          _c("expanded-item", { attrs: { isOpen: _vm.isOpen } }, [
            _c(
              "div",
              { staticClass: "container" },
              _vm._l(_vm.children, function (child) {
                return _c(
                  "div",
                  {
                    key: child.link,
                    staticClass: "link-shrink",
                    class: { active: _vm.isActive(child) },
                    on: {
                      click: function ($event) {
                        return _vm.onLinkClick(child)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "sidemenu-icon-shrink",
                      attrs: { src: child.icon },
                    }),
                    _c("span", { staticClass: "link-text link-text-shrink" }, [
                      _vm._v(_vm._s(child.text)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "closed",
          on: { mouseover: _vm.onMouseOver, mouseleave: _vm.onMouseLeave },
        },
        [
          _c(
            "div",
            {
              staticClass: "link",
              class: { shrink: true, active: _vm.isCurrent },
              on: { click: () => _vm.onLinkClick() },
            },
            [
              _c("img", {
                staticClass: "sidemenu-icon",
                class: { shrink: true },
                attrs: { src: _vm.icon, alt: _vm.text },
              }),
            ]
          ),
          _vm.isMouseHover
            ? _c("div", { staticClass: "float-menu" }, [
                _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
                _c(
                  "div",
                  { staticClass: "container" },
                  _vm._l(_vm.children, function (child) {
                    return _c(
                      "div",
                      {
                        key: child.link,
                        staticClass: "item",
                        on: {
                          click: function ($event) {
                            return _vm.onLinkClick(child)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "sidemenu-icon-shrink",
                          attrs: { src: child.icon },
                        }),
                        _c(
                          "span",
                          { staticClass: "link-text link-text-shrink" },
                          [_vm._v(_vm._s(child.text))]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }