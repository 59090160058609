var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "card", on: { click: _vm.onClickCard } }, [
    _c("img", {
      staticClass: "banner",
      attrs: { src: _vm.bannerImg, alt: "ogp", width: "280", height: "150" },
    }),
    _c("div", { staticClass: "text-container" }, [
      _c("div", { staticClass: "name-container" }, [
        _c("img", {
          staticClass: "icon",
          attrs: { src: _vm.iconImg, alt: "icon", width: "50", height: "50" },
        }),
        _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
      ]),
      _c("div", { staticClass: "comment" }, [_vm._v(_vm._s(_vm.comment))]),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "button-container" }, [
      _c("button", { staticClass: "button" }, [_vm._v("詳しく見る")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }