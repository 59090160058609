var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-content" }, [
        _c("header", [_vm._v(_vm._s(_vm.title))]),
        _c(
          "div",
          { staticClass: "dialog-body" },
          [
            _c("span", { staticClass: "message" }, [
              _vm._v(_vm._s(_vm.content)),
            ]),
            _c("input-text", {
              staticClass: "input attribute",
              attrs: {
                label: _vm.placeholder,
                value: _vm.value,
                maxlength: _vm.limit,
              },
              on: { input: (val) => (_vm.value = val) },
            }),
            _vm.error.error
              ? _c("span", { staticClass: "error" }, [
                  _vm._v(_vm._s(_vm.error.message)),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c("footer", [
          _c("div", { staticClass: "button-area" }, [
            _c(
              "button",
              {
                staticClass: "button cancel",
                on: { click: _vm.onNegativeClick },
              },
              [_vm._v(_vm._s(_vm.negative))]
            ),
            _c(
              "button",
              {
                staticClass: "button save",
                on: { click: _vm.onPositiveClick },
              },
              [_vm._v(_vm._s(_vm.positive))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }