var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "router-link",
    { staticClass: "icon-mypage", attrs: { to: _vm.to } },
    [
      _vm.src
        ? _c("img", {
            staticClass: "icon",
            attrs: { src: _vm.src, height: "100%" },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }