var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.novelFromNolaNovel
    ? _c("NolaNovelListItem", {
        attrs: { novel: _vm.novel },
        scopedSlots: _vm._u(
          [
            {
              key: "container",
              fn: function () {
                return [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                  _c("div", { staticClass: "data" }, [
                    _c("div", [_vm._v("前回の更新：" + _vm._s(_vm.updatedAt))]),
                    _c("div", [
                      _vm._v("配信開始日：" + _vm._s(_vm.publishedAt)),
                    ]),
                    _c("div", [
                      _vm._v("投稿エピソード数：" + _vm._s(_vm.episodeCount)),
                    ]),
                    _c("div", [
                      _vm._v("投稿文字数：" + _vm._s(_vm.characterCount)),
                    ]),
                  ]),
                  _c("div", { staticClass: "stats" }, [
                    _c(
                      "div",
                      { staticClass: "stats-item" },
                      [
                        _c("star-outline-icon", {
                          staticClass: "icon",
                          attrs: { size: 20 },
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.review))]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "stats-item" },
                      [
                        _c("eye-outline-icon", {
                          staticClass: "icon",
                          attrs: { size: 20 },
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.pv))]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "stats-item" },
                      [
                        _c("bookmark-outline-icon", {
                          staticClass: "icon",
                          attrs: { size: 20 },
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.bookmark))]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "stats-item" },
                      [
                        _c("comment-text-outline-icon", {
                          staticClass: "icon",
                          attrs: { size: 20 },
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.comment))]),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "right",
              fn: function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "button primary",
                      on: { click: _vm.onClickCreateEpisode },
                    },
                    [
                      _c("div", [_vm._v("エピソードを投稿する")]),
                      _c("OpenInNewIcon", {
                        staticClass: "icon",
                        attrs: { size: 16 },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button secondary",
                      on: { click: _vm.onClickUpdateNovel },
                    },
                    [
                      _c("div", [_vm._v("作品情報を更新する")]),
                      _c("OpenInNewIcon", {
                        staticClass: "icon",
                        attrs: { size: 16 },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button tertiary",
                      on: { click: _vm.onClickTransNovel },
                    },
                    [
                      _c("div", [_vm._v("作品掲載ページに移動する")]),
                      _c("OpenInNewIcon", {
                        staticClass: "icon",
                        attrs: { size: 16 },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2724381010
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }