var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "contest-entry-item" },
    [
      _c(
        "div",
        { staticClass: "name-container" },
        [
          _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
          _vm.required !== undefined
            ? [
                _vm.required
                  ? _c("div", { staticClass: "batch required" }, [
                      _vm._v("必須"),
                    ])
                  : _c("div", { staticClass: "batch optional" }, [
                      _vm._v("任意"),
                    ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.description
        ? _c("div", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.description)),
          ])
        : _vm._e(),
      _vm._t("default"),
      _vm.error
        ? _c("div", { staticClass: "error-message" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }