var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container" },
    _vm._l(_vm.sideMenuItems, function (sideMenuItem) {
      return _c(
        "div",
        { key: sideMenuItem.link },
        [
          !sideMenuItem.canExpand && !sideMenuItem.canEdit
            ? _c("side-menu-link", {
                attrs: {
                  expand: _vm.expand,
                  novelId: _vm.novelId,
                  link: sideMenuItem.link,
                  text: sideMenuItem.text,
                  icon: sideMenuItem.icon,
                },
              })
            : sideMenuItem.canExpand && !sideMenuItem.canEdit
            ? _c("side-menu-expand-link", {
                attrs: {
                  expand: _vm.expand,
                  novelId: _vm.novelId,
                  link: sideMenuItem.link,
                  text: sideMenuItem.text,
                  icon: sideMenuItem.icon,
                  children: sideMenuItem.children,
                },
              })
            : _c("side-menu-expand-editable-link", {
                attrs: {
                  expand: _vm.expand,
                  novelId: _vm.novelId,
                  link: sideMenuItem.link,
                  text: sideMenuItem.text,
                  icon: sideMenuItem.icon,
                },
              }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }