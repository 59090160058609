var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "news" },
    [
      _c("loading", { attrs: { loaded: _vm.news.length } }, [
        _c("h2", { key: "title", staticClass: "h2" }, [_vm._v("お知らせ")]),
        _c(
          "div",
          { key: "news", staticClass: "accbox" },
          [
            _vm._l(_vm.news, function (newsItem) {
              return [
                _c("div", { key: newsItem.id }, [
                  _c("label", { attrs: { for: newsItem.id } }, [
                    _vm._v(_vm._s(newsItem.title)),
                  ]),
                  _c("input", {
                    staticClass: "cssacc",
                    attrs: { type: "checkbox", id: newsItem.id },
                  }),
                  _c("div", { staticClass: "accshow" }, [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(newsItem.text) },
                    }),
                  ]),
                ]),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { key: "pager", staticClass: "pager" },
          [
            _c("div", { staticClass: "pager-context" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.currentPage * _vm.limit - _vm.limit + 1) +
                  "〜" +
                  _vm._s(
                    _vm.currentPage * _vm.limit > _vm.totalCount
                      ? _vm.totalCount
                      : _vm.currentPage * _vm.limit
                  ) +
                  "件目まで表示（" +
                  _vm._s(_vm.totalCount) +
                  "件中） "
              ),
            ]),
            _c("paginate", {
              key: "paginate",
              attrs: {
                "page-count": _vm.getPageCount,
                "click-handler": _vm.clickCallback,
                "container-class": "pagination",
                "page-class": "page-item",
                "prev-class": "prev",
                "next-class": "next",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "space" }),
      _c("site-footer", { staticClass: "footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }