var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "account-deleted" }, [
    _c(
      "div",
      { staticClass: "box" },
      [
        _c("div", { staticClass: "text" }, [
          _c("div", { staticClass: "title" }, [
            _c("h2", [_vm._v(_vm._s(_vm.title))]),
          ]),
          _c("div", { staticClass: "description" }, [
            _c("span", [_vm._v(_vm._s(_vm.description))]),
          ]),
        ]),
        _c("router-link", { attrs: { to: { name: "top-lp" } } }, [
          _c("button", { staticClass: "secondary" }, [
            _vm._v("サイトTOPに戻る"),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }