var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.early
    ? _c(
        "div",
        [
          _c(
            "transition-group",
            { attrs: { name: "fade", mode: "out-in" } },
            [
              !_vm.loaded
                ? _c("div", { staticClass: "spinner" }, [
                    _c("img", {
                      attrs: { src: require("@/assets/img/loading-s.gif") },
                    }),
                  ])
                : [_vm._t("default")],
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }