var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-content" }, [
        _c("h3", { staticClass: "dialog-title" }, [
          _vm._v("新しい作品として複製するデータを選択してください"),
        ]),
        _vm._m(0),
        _c("hr"),
        _c(
          "div",
          { staticClass: "checkbox-select-all" },
          [
            _c("checkbox-icon", {
              attrs: {
                isChecked: _vm.allItemsChecked,
                fill: _vm.checkBoxColor,
                emitEvent: true,
              },
              on: { change: _vm.toggleSelectAll },
            }),
            _c("label", { on: { click: _vm.toggleSelectAll } }, [
              _vm._v("すべてのデータを選択する"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "checkbox-list" },
          _vm._l(_vm.items, function (item) {
            return _c(
              "div",
              {
                key: item.label,
                staticClass: "checkbox-item",
                class: { disabled: item.required, indented: true },
              },
              [
                _c("checkbox-icon", {
                  attrs: {
                    isChecked: item.checked,
                    fill: item.required
                      ? _vm.disableCheckBoxColor
                      : _vm.checkBoxColor,
                    emitEvent: true,
                  },
                  on: {
                    change: (value) => _vm.handleCheckboxChange(item, value),
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "is-show-label",
                    class: { disabled: item.required },
                    on: {
                      click: function ($event) {
                        return _vm.handleCheckboxChange(item, !item.checked)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.label) + " ")]
                ),
              ],
              1
            )
          }),
          0
        ),
        _c("p", { staticClass: "dialog-note" }, [
          _vm._v("※がついている資料は関連資料の複製が必須になります。"),
        ]),
        _c("footer", [
          _c(
            "button",
            {
              staticClass: "button primary",
              class: { disabled: _vm.isButtonDisabled },
              attrs: { disabled: _vm.isButtonDisabled },
              on: { click: _vm.onPositiveClick },
            },
            [_vm._v(" 複製する ")]
          ),
          _c(
            "a",
            { staticClass: "cancel-text", on: { click: _vm.onNegativeClick } },
            [_vm._v("キャンセル")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "dialog-description" }, [
      _vm._v(" この作品のデータを任意で選択して、新しい作品として複製します。"),
      _c("br"),
      _vm._v("作品を丸ごと複製する場合は全てのデータを選択してください。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }