var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.contests.length
      ? _c(
          "div",
          { staticClass: "body" },
          _vm._l(_vm.contests, function (contest) {
            return _c("contest-row", {
              key: contest.id,
              staticClass: "row",
              attrs: { contest: contest },
            })
          }),
          1
        )
      : _c("div", { staticClass: "empty" }, [
          _vm._v("該当するコンテストがありません"),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }