var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "expand" },
    [
      _c(
        "transition",
        {
          attrs: { name: "expand" },
          on: {
            enter: _vm.enter,
            leave: _vm.leave,
            "after-enter": _vm.afterEnter,
            "after-leave": _vm.afterLeave,
          },
        },
        [
          _vm.isOpen
            ? _c(
                "div",
                { staticClass: "expand-target" },
                [_vm._t("default")],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }