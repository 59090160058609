var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "div",
        { staticClass: "side" },
        [
          _vm.isPC
            ? _c("contest-notification", {
                staticClass: "side-content",
                attrs: { contests: _vm.contests },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "analytics" },
            [
              _c("writing-data", { staticClass: "side-content" }),
              _vm.isConnectedNolaNovel
                ? _c("nola-novel-data", { staticClass: "side-content" })
                : _vm._e(),
            ],
            1
          ),
          _c("banner-list", {
            staticClass: "side-content",
            attrs: { banner: _vm.banner },
          }),
        ],
        1
      ),
      _c("novel-list", {
        key: "novel-list",
        staticClass: "novel-list",
        on: { changeLoading: _vm.changeLoading },
      }),
      _vm.isLoading
        ? _c("MaskedLoading", { staticClass: "loading" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }