var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("EventHeader"),
      _c(
        "div",
        { staticClass: "body" },
        [
          _vm.isLoading
            ? _c("vue-loading", {
                attrs: {
                  type: "spiningDubbles",
                  color: "#efad4c",
                  size: { width: "50px", height: "50px" },
                },
              })
            : [
                _c("div", { staticClass: "title" }, [
                  _vm._v("編集部に作品を応募する"),
                ]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(
                    " Nolaから利用できる投稿サイトNolaノベルの「編集部の掲示板」では、各社の編集部が探している作品像を公開しています。"
                  ),
                  _c("br"),
                  _vm._v(
                    "作品像を確認し投稿すると、編集部から足あとや感想、出版スカウトが届くことがあります。 "
                  ),
                ]),
                _vm.companies.length
                  ? _c(
                      "div",
                      { staticClass: "card-list" },
                      [
                        _vm._l(_vm.companies, function (company) {
                          return [
                            _c("CompanyCard", {
                              key: company.id,
                              attrs: { company: company },
                            }),
                          ]
                        }),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "empty-card" }, [
                      _vm._v("該当する編集部の掲示板がありません"),
                    ]),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }