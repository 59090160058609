var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "card", on: { click: _vm.onClickCard } }, [
    _c(
      "div",
      { staticClass: "status", class: { closed: _vm.status === "募集終了" } },
      [_vm._v(_vm._s(_vm.status))]
    ),
    _c("img", { attrs: { src: _vm.image, alt: "ogp", width: "280" } }),
    _c("div", { staticClass: "text-container" }, [
      _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.title))]),
      _c("div", { staticClass: "department" }, [
        _vm._v(_vm._s(_vm.department)),
      ]),
      _c("div", { staticClass: "deadline" }, [
        _vm._v(_vm._s(`締切：${_vm.deadline}`)),
      ]),
      _c("div", { staticClass: "free" }, [_vm._v(_vm._s(_vm.free))]),
      _c("div", { staticClass: "tag" }, [_vm._v(_vm._s(_vm.tag))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }