var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "input-text-v2" }, [
    _vm.type === "checkbox" && !_vm.rows
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.internalValue,
              expression: "internalValue",
            },
          ],
          class: { error: _vm.error },
          attrs: {
            placeholder: _vm.placeholder,
            min: _vm.min,
            max: _vm.max,
            minlength: _vm.minlength,
            maxlength: _vm.maxlength,
            readonly: _vm.readonly,
            type: "checkbox",
          },
          domProps: {
            checked: Array.isArray(_vm.internalValue)
              ? _vm._i(_vm.internalValue, null) > -1
              : _vm.internalValue,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.internalValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.internalValue = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.internalValue = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.internalValue = $$c
              }
            },
          },
        })
      : _vm.type === "radio" && !_vm.rows
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.internalValue,
              expression: "internalValue",
            },
          ],
          class: { error: _vm.error },
          attrs: {
            placeholder: _vm.placeholder,
            min: _vm.min,
            max: _vm.max,
            minlength: _vm.minlength,
            maxlength: _vm.maxlength,
            readonly: _vm.readonly,
            type: "radio",
          },
          domProps: { checked: _vm._q(_vm.internalValue, null) },
          on: {
            change: function ($event) {
              _vm.internalValue = null
            },
          },
        })
      : !_vm.rows
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.internalValue,
              expression: "internalValue",
            },
          ],
          class: { error: _vm.error },
          attrs: {
            placeholder: _vm.placeholder,
            min: _vm.min,
            max: _vm.max,
            minlength: _vm.minlength,
            maxlength: _vm.maxlength,
            readonly: _vm.readonly,
            type: _vm.type,
          },
          domProps: { value: _vm.internalValue },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.internalValue = $event.target.value
            },
          },
        })
      : _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.internalValue,
              expression: "internalValue",
            },
          ],
          class: { error: _vm.error },
          attrs: {
            placeholder: _vm.placeholder,
            minlength: _vm.minlength,
            maxlength: _vm.maxlength,
            readonly: _vm.readonly,
            rows: _vm.rows,
          },
          domProps: { value: _vm.internalValue },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.internalValue = $event.target.value
            },
          },
        }),
    _vm.isDisplayCount
      ? _c("div", { staticClass: "count" }, [
          _c("span", [_vm._v(_vm._s(_vm.internalValue.length))]),
          _c("span", [_vm._v("/")]),
          _c("span", [_vm._v(_vm._s(_vm.maxlength))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }